@import 'global';

// Color palette for entire website.
$darkest: #1d1b1b;
$dark: #222;
$white: #fff;
$gray: #303030;
$green: #00bc8c;
$lightGray: #d3d3d3;
$lighterGray: #f5f5f5;

// Dark mode colors.
.dark {
  $about-bg-color: $gray;
  $about-text-color: $white;
  $project-bg-color: $dark;
  $project-text-color: $white;
  $project-card-border-color: $gray;
  $project-card-bg-color: $gray;

  @import 'styles';
}

// Light mode colors.
.light {
  $about-bg-color: $white;
  $about-text-color: $dark;
  $project-bg-color: $lighterGray;
  $project-text-color: $dark;
  $project-card-border-color: $lightGray;
  $project-card-bg-color: $white;

  @import 'styles';
}
