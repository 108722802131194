@import 'tooltips';

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 87.375px;

  border-bottom: 1px solid $white;

  background-color: $dark;

  // Separate two flex items.
  justify-content: space-between;

  padding: 0.7rem 2rem;

  // Keep at top of screen & on top of all content.
  position: fixed;
  z-index: 1;
  top: 0;

  ul {
    // You got me moving side to side...
    display: flex;
  }

  a {
    color: $white;

    // Anchor tags within navbar that are hovered...
    // Same as a:hover.
    &:hover {
      color: $green;
    }
  }

  // Navbar brand.
  h1 a {
    font-size: 2rem;
    font-weight: normal;
  }

  ul a {
    text-transform: uppercase;
    padding: 0.5rem;
    margin: 0 0.25rem;
  }

  .toggleThemeButton {
    border: none;
    background: transparent;
    cursor: pointer;
    color: $white;
    font-size: 1.4rem;
    padding: 1rem;
  }
}

.landing {
  height: 100vh;
  background-color: $dark;
  color: $white;

  .intro {
    top: 38%;
    position: absolute;

    font-size: 2.2rem;

    // Center everything!
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    left: 0;
    right: 0;
  }
}

.btn {
  font-size: 1.25rem;
  background-color: $gray;
  color: $white;
  text-align: center;
  padding: 0.7rem 0.8rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 0.1rem solid $white;
  transition: all 0.2s ease-in;

  &:hover {
    background: lighten($dark, 30%);
  }
}

// Work experience timeline styles.
.work {
  min-height: 100vh;
  background-color: $about-bg-color;
  color: $about-text-color;

  // Found with inspect element.
  // Buttons at the bottom of the timeline.
  .TimelineControlContainer-iRFZwa {
    margin-bottom: 5rem;
  }

  // Hiding the dots on the timeline from react-chrono.
  // Because they appear above the navigation bar on scroll.
  .Shape-dPtZym {
    display: none;
  }

  h1.work-title {
    font-size: 3.5rem;
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 3rem;
  }

  // From react-chrono, found through inspect element.
  .kzPzXb {
    justify-content: left;
  }

  // From react-chrono, found through inspect element.
  .eYlYRs {
    color: black;
  }
}

// List of technologies page.
.tech {
  min-height: 100vh;
  background-color: $project-bg-color;
  color: $project-text-color;
  text-align: center;

  .single-course-container {
    display: flex;
    flex-direction: row;
  }

  // Two adjacent technology listings to save space.
  .adjacent-tech-listings {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    gap: 15%;
  }

  .last-tech-listing {
    padding-bottom: 7rem;
  }

  // Icons for categories of technologies.
  .tech-listing {
    text-align: center;
  }

  h1 {
    font-size: 3.5rem;
    text-align: center;
    padding-top: 5rem;
  }

  .expand-btn {
    color: $green;
  }
}

.about {
  min-height: 100vh;

  // Change to pure white color.
  background-color: $about-bg-color;

  // Change to pure black color.
  color: $about-text-color;

  h1 {
    font-size: 3.5rem;
    text-align: center;
    padding-top: 5rem;
  }

  .biography {
    text-align: center;
    font-size: 1.5rem;

    p {
      padding-top: 5rem;
      padding-bottom: 5rem;
      padding-left: 20rem;
      padding-right: 20rem;
    }

    a {
      color: $green;
    }
  }
}

// Particles (landing page).
.stars {
  height: 100%;

  #tsparticles {
    height: 100%;
  }

  canvas {
    width: 100%;
    height: 100%;
  }
}

.technology-list-container {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 5rem;

  div div {
    padding: 1rem 1.2rem;
    font-size: 1.3em;

    .devicon-label {
      font-family: 'Lato' !important;
    }
  }

  div span {
    display: block;
  }

  div .devicon {
    font-size: 4rem;
  }
}

.TimelineNavWrapper-eckVw.gOnyOe.timeline-controls.rc-controls {
  margin-bottom: 5rem;
}

// Styles for projects.
.projects {
  min-height: 100vh;

  // Change to #f5f5f5.
  background-color: $project-bg-color;

  // Change to black.
  color: $project-text-color;
  padding-bottom: 8rem;

  .project-title {
    font-size: 3.5rem;
    text-align: center;
    padding: 5rem 0;
  }

  .card {
    // Change to black.
    border: 1px solid $project-card-border-color;

    // Change to white.
    background-color: $project-card-bg-color;
    border-radius: 0.2rem;
    width: 90%;
    margin: auto;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .title {
    color: grey;
  }

  a {
    text-decoration: none;
    font-size: 1rem;
    color: $green;

    &:hover {
      color: darken(#007053, 5%);
    }
  }

  .card-links {
    a {
      padding: 0 0.3rem;
    }

    .chip {
      padding: 0 0.2rem;
      margin: 1rem 0;
      background: $green;
      color: white;
    }
  }

  .chip-container {
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

/* Footer styles. */

.footer {
  padding: 7% 15%;
  text-align: center;
  background-color: $darkest;
  color: $white;
}

/* Font awesome footer icons. */
.footer-icon {
  margin: 20px 10px;
  font-size: 30px;
  color: $white;
}

.last-updated {
  font-size: 1rem;
  color: $white;
}

/* Designed by Jacob logo. */
.footer-text {
  font-size: 20px;
}

/* Turn font awesome icons green on hover. */
i.footer-icon:hover {
  color: $green;
  text-decoration: none;
}

// Express devicon.
.devicon-express-original.colored {
  color: rgb(139, 139, 139);
}

#projectName {
  color: #00bc8c;
}

@import 'mobile';
