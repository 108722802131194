// Styles for smaller screen sizes.
@media (max-width: 1500px) {
  .about .biography p {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 1000px) {
  .return-to-top {
    visibility: hidden;
  }

  .tech .adjacent-tech-listings {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 2rem;
  }

  .fields-tech-listing {
    padding-bottom: 7rem;
  }

  .projects .chip-container {
    display: none;
  }
}

@media (max-width: 600px) {
  .navbar h1 a {
    font-size: 1.5rem;
  }

  .navbar ul li a.nav-link {
    font-size: 0.8rem;
    padding: 0;
  }

  .navbar .toggleThemeButton {
    font-size: 1rem;
  }
}

@media (max-width: 550px) {
  .about h1 {
    font-size: 2.5rem;
  }

  .projects .project-title {
    font-size: 2.5rem;
  }

  .intro .header {
    font-size: 2.5rem;
  }

  .technology-list-container div .devicon {
    font-size: 2rem;
  }

  .devicon-label {
    visibility: hidden;
  }

  .technology-list-container div div {
    padding: 0rem;
  }

  .about {
    .biography {
      p {
        font-size: 1rem;
      }
    }
  }

  .card {
    h1 {
      font-size: 1.9rem;
    }
  }
}
